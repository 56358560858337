import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import useWindowSize from "../../../../../Utils/useWindowSize/useWindowSize";
import { historyData } from "./HistoryData/historydata";
import "./SectionFour.scss";

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
};



function SectionFour() {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  return (
    <section className="section-four">
      <div className="history">
        <div className="container">
          <div className="boxHistory">
            <div className="title" id="fourthPage-title">
              {t("section3Head")}
            </div>
            <div className="boxContent" id="fourthPage-container">
              {width > 991 ? (
                historyData.map((item, idx) => (
                  <div key={item.index} className="boxContent_item">
                    <div className="year">{item.year}</div>
                    <div className="itemList">
                      {item?.listItem.map((list, idxs) => (
                        <div className="box" key={list.index}>
                          <p className="box_bold">{t(`section4Data.${item.index}.listItem.${list.index}.number`)}</p>
                          <p className="box_text">{t(`section4Data.${item.index}.listItem.${list.index}.text`)}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <Slider {...settings}>
                  {historyData.map((item, idx) => (
                    <div key={idx} className="boxContent_item">
                      <div className="year">{item.year}</div>
                      <div className="itemList">
                        {item?.listItem.map((list, idxs) => (
                          <div className="box" key={idxs}>
                            <p className="box_bold">{list.number}</p>
                            <p className="box_text">{list.text}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionFour;
